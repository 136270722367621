<template>
  <div class="m-5">
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
        <el-breadcrumb-item :to="{ name: 'EditFaqHome' }"
          >Home</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ name: 'EditFaqMain' }"
          >關於我們</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ name: 'EditFaqCategory' }"
          >Category ({{
            helper.displayI18nText($i18n.locale, getCurrentLabel.label)
          }})</el-breadcrumb-item
        >
        <el-breadcrumb-item
          >Topic ({{
            helper.displayI18nText($i18n.locale, getCurrentTopic.title)
          }})</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="d-flex">
      <div>
        <h2>Topic ({{ helper.displayI18nText($i18n.locale, getCurrentTopic.title) }})</h2>
      </div>
      <div class="pl-4" style="padding-top:6px;">
        <el-button
          type="success"
          size="small"
          icon="el-icon-plus"
          @click="dialogCreateVisible = true"
          >新增 FAQ</el-button
        >
      </div>
    </div>

    <div>
      <el-table :data="getFaqListWithId.faqs" style="width: 100%">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column label="Question" width="500">
          <template slot-scope="scope">
            {{ helper.displayI18nText($i18n.locale, scope.row.question) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button
              @click="handleEditFaq(scope.row)"
              type="success"
              plain
              size="mini"
            >
              <i class="el-icon-edit"></i>
              編輯
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
            >
              <i class="el-icon-delete"></i>
              刪除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br />
    </div>

    <!-- dialog create new faq -->
    <el-dialog
      title="Create FAQ"
      :visible.sync="dialogCreateVisible"
      width="80%"
      :close-on-click-modal="false"
      :before-close="handleCancelCreateFaq"
    >
      <el-form>
        <el-tabs type="border-card" v-model="defaultTabCreate">
          <el-tab-pane label="FAQ (EN)" name="1">
            <el-form-item label="Question (EN)">
              <el-input v-model="newFaq.question.en"></el-input>
            </el-form-item>
            <span>Explanation (EN)</span>
            <ckeditor class="mt-3" v-model="newFaq.explanation.en"></ckeditor>
          </el-tab-pane>
          <el-tab-pane label="FAQ (TW)" name="2">
            <el-form-item label="Question (TW)">
              <el-input v-model="newFaq.question.tw"></el-input>
            </el-form-item>
            <span>Explanation (TW)</span>
            <ckeditor class="mt-3" v-model="newFaq.explanation.tw"></ckeditor>
          </el-tab-pane>
          <el-tab-pane label="FAQ (CN)" name="3">
            <el-form-item label="Question (CN)">
              <el-input v-model="newFaq.question.cn"></el-input>
            </el-form-item>
            <span>Explanation (CN)</span>
            <ckeditor class="mt-3" v-model="newFaq.explanation.cn"></ckeditor>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelCreateFaq">Cancel</el-button>
        <el-button type="primary" @click="submitCreateFaq">Submit</el-button>
      </span>
    </el-dialog>

    <!-- dialog edit selected FAQ -->
    <el-dialog
      title="Edit FAQ"
      :visible.sync="dialogEditVisible"
      width="80%"
      :close-on-click-modal="false"
      :before-close="handleCancelEditFaq"
    >
      <el-form>
        <el-tabs type="border-card" v-model="defaultTabEdit">
          <el-tab-pane label="FAQ (EN)" name="1">
            <el-form-item label="Question (EN)">
              <el-input v-model="currentSelectedFaq.question.en"></el-input>
            </el-form-item>
            <span>Explanation (EN)</span>
            <ckeditor
              class="mt-3"
              v-model="currentSelectedFaq.explanation.en"
            ></ckeditor>
          </el-tab-pane>
          <el-tab-pane label="FAQ (TW)" name="2">
            <el-form-item label="Question (TW)">
              <el-input v-model="currentSelectedFaq.question.tw"></el-input>
            </el-form-item>
            <span>Explanation (TW)</span>
            <ckeditor
              class="mt-3"
              v-model="currentSelectedFaq.explanation.tw"
            ></ckeditor>
          </el-tab-pane>
          <el-tab-pane label="FAQ (CN)" name="3">
            <el-form-item label="Question (CN)">
              <el-input v-model="currentSelectedFaq.question.cn"></el-input>
            </el-form-item>
            <span>Explanation (CN)</span>
            <ckeditor
              class="mt-3"
              v-model="currentSelectedFaq.explanation.cn"
            ></ckeditor>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelEditFaq">Cancel</el-button>
        <el-button type="primary" @click="submitUpdateFaq">Submit</el-button>
      </span>
    </el-dialog>

    <!-- dialog delete current Faq -->
    <el-dialog
      title="Delete FAQ"
      :visible.sync="dialogDeleteVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleCancelDeleteFaq"
    >
      <div>是否要刪除此 Faq?</div>
      <div>en: {{ currentSelectedFaq.question.en }}</div>
      <div>tw: {{ currentSelectedFaq.question.tw }}</div>
      <div>cn: {{ currentSelectedFaq.question.cn }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelDeleteFaq">Cancel</el-button>
        <el-button type="danger" @click="submitDeleteFaq">Delete</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";
import _ from "lodash";

export default {
  data() {
    return {
      newFaq: {},
      currentSelectedFaq: {},
      dialogCreateVisible: false,
      dialogEditVisible: false,
      dialogDeleteVisible: false,
      defaultTabCreate: "1",
      defaultTabEdit: "1"
    };
  },
  computed: {
    ...mapGetters("faq", [
      "getFaqListWithId",
      "getCurrentTopic",
      "getCurrentLabel"
    ]),
    helper() {
      return helper;
    }
  },
  async created() {
    this.clearNewFaq();
    this.clearCurrentSelectedFaq();
    await this.$store.dispatch("faq/getFaqList", this.getCurrentTopic.id);
  },
  async mounted() {},
  methods: {
    clearNewFaq() {
      this.newFaq = {
        faq_topic_id: this.getCurrentTopic.id,
        question: {
          en: "",
          tw: "",
          cn: ""
        },
        explanation: {
          en: "",
          tw: "",
          cn: ""
        }
      };
    },
    clearCurrentSelectedFaq() {
      this.currentSelectedFaq = {
        faq_topic_id: this.getCurrentTopic.id,
        question: {
          en: "",
          tw: "",
          cn: ""
        },
        explanation: {
          en: "",
          tw: "",
          cn: ""
        }
      };
    },

    // create new faq
    // 點選 "取消"
    handleCancelCreateFaq() {
      this.dialogCreateVisible = false;
      this.clearNewFaq();
      this.defaultTabCreate = "1";
    },
    // 點選 "送出"
    async submitCreateFaq() {
      this.dialogCreateVisible = false;
      await this.$store.dispatch("faq/createFaq", this.newFaq);
      this.clearNewFaq();
      await this.$store.dispatch("faq/getFaqList", this.getCurrentTopic.id);
      this.defaultTabCreate = "1";
    },

    // edit faq
    // 在 table 點選某項 faq 時，assign 此 faq 至 currentSelectedFaq
    handleEditFaq(faq) {
      this.currentSelectedFaq = _.cloneDeep(faq);
      this.dialogEditVisible = true;
    },
    // 點選 "取消"
    handleCancelEditFaq() {
      this.dialogEditVisible = false;
      this.clearCurrentSelectedFaq();
      this.defaultTabEdit = "1";
    },
    // 點選 "送出"
    async submitUpdateFaq() {
      await this.$store.dispatch("faq/updateFaq", this.currentSelectedFaq);
      this.clearCurrentSelectedFaq();
      this.dialogEditVisible = false;
      await this.$store.dispatch("faq/getFaqList", this.getCurrentTopic.id);
      this.defaultTabEdit = "1";
    },

    // delete faq
    handleDelete(faq) {
      this.currentSelectedFaq = _.cloneDeep(faq);
      this.dialogDeleteVisible = true;
    },
    handleCancelDeleteFaq() {
      this.dialogDeleteVisible = false;
      this.clearCurrentSelectedFaq();
    },
    async submitDeleteFaq() {
      await this.$store.dispatch("faq/deleteFaq", this.currentSelectedFaq.id);
      this.clearCurrentSelectedFaq();
      this.dialogDeleteVisible = false;
      await this.$store.dispatch("faq/getFaqList", this.getCurrentTopic.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-button {
  outline: none;
}
</style>
